// import axios from "axios";
// import env from "../config";
import axios from "../api/axios";
// const client = axios.create({
//   baseURL: env.TOOLS_ENDPOINT,
//   json: true,
// });

export default {
  async execute(method, resource, data,requestheader = {}) {
    console.log("called");
    return axios.toolsEndPoint({
      method,
      url: resource,
      data,
      headers: requestheader
    }).then((req) => {
      return req.data;
    });
  },
  UploadGroupTools(payload,headerpayload){
    let requestheader = headerpayload
    return this.execute("post", `/GroupAssignment`,payload,
    requestheader
    );
  },
  GetGroupTools(payload){
    return this.execute("get", `/GroupAssignment/Logs`,null,payload);
  },
  ExecuteGroupTools(payload,headerpayload){
    return this.execute("put", `/GroupAssignment/Execute`,payload,headerpayload);
  },
  GetGroupById(toolId,headerpayload){
    return this.execute("get", `/GroupAssignment/Log/${toolId}`,null,headerpayload);
  },
};
